<template>
  <div class="flex flex-1 flex-col h-full">
    <ui-loader
      static
      :loading="myRoomStore.loadings[type]"
      :error="error"
      :show-not-found="!myRoomStore.rooms[type].length"
    >
      <template #default>
        <transition-fade
          group
          mode="out-in"
          tag="div"
          class="flex flex-col gap-2.5 overflow-auto max-h-full min-h-40"
        >
          <common-rooms-card
            v-for="room in myRoomStore.rooms[type]"
            :key="room.id"
            :room="room"
            :type="type"
          />
        </transition-fade>
      </template>
      <template #content-empty>
        <div class="text-center my-auto shrink-0">
          <ui-typography
            :text="emptyContent.title"
            variant="h3"
          />
          <ui-typography
            v-if="emptyContent.description"
            :text="emptyContent.description"
            variant="h3"
          />
        </div>
      </template>
    </ui-loader>
    <div
      v-if="type === RoomFilterType.Admin"
      class="mt-auto pt-4 flex"
    >
      <ui-button
        text="Create My Room"
        type="submit"
        active-class="!bg-primary !border-primary"
        size="lg"
        full
        class="max-w-md mx-auto"
        :to="{ name: 'dashboard-rooms-create' }"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { type TRoomFilterType, RoomFilterType } from '~/globals/RoomFilterType';

  const REFRESH_TIME = 60 * 1000; // 60s
  type Props = { type: TRoomFilterType };
  const props = withDefaults(defineProps<Props>(), {
    type: RoomFilterType.Member
  });

  const myRoomStore = useMyRoomStore();
  const { subscriptionLimitations } = useAuthUser();
  const appStore = useAppStore();

  const emptyContent = computed(() => {
    switch (props.type) {
      case RoomFilterType.Admin: {
        return {
          title: 'Nothing Here Yet',
          description: `You can create up to ${subscriptionLimitations.value?.rooms_as_owner_limit || 1} of your own rooms or upgrade the plan`
        };
      }
      default: {
        return {
          title: 'There are no rooms'
        };
      }
    }
  });

  const { error } = useLazyAsyncData('fetchRooms', () => myRoomStore.fetchRooms(props.type));

  useIntervalFn(() => {
    if (!appStore.isDisconnect) {
      myRoomStore.fetchRooms(props.type, true);
    }
  }, REFRESH_TIME);

  useWatchDisconnect({
    online() {
      myRoomStore.fetchRooms(props.type, true);
    }
  });

  onUnmounted(() => {
    myRoomStore.setRooms([], props.type);
  });
</script>
