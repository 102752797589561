<template>
  <div
    class="flex gap-2.5 p-1.5 rounded-xs items-center border cursor-pointer"
    :class="cardTypeClasses"
    tabindex="0"
    @click="goToRoom(room)"
    @keydown.enter="goToRoom(room)"
  >
    <div class="flex">
      <ui-image
        :src="room.avatar_url"
        :alt="room.name"
        class="rounded-md w-12 h-12 aspect-square object-cover"
      />
    </div>
    <div class="overflow-hidden flex-1">
      <ui-typography
        tag="h6"
        variant="h6"
        :text="room.name"
        class="text-primary truncate"
      />
      <common-room-counters :room="room" />
    </div>
    <ui-chip
      v-if="labelInfoAttrs"
      v-tooltip="labelInfoAttrs.tooltip || undefined"
      v-bind="labelInfoAttrs.props"
      size="xs"
      class="min-w-6 h-6 shrink-0"
    >
      <ui-typography
        v-if="labelInfoAttrs.count"
        variant="small"
      >
        {{ labelInfoAttrs.count }}
      </ui-typography>
    </ui-chip>
  </div>
</template>

<script setup lang="ts">
  import { RoomFilterType, type TRoomFilterType } from '~/globals/RoomFilterType';
  type Props = {
    room: Room;
    type?: TRoomFilterType;
  };
  const props = defineProps<Props>();
  const route = useRoute('dashboard-rooms-roomId');
  const memberUserStatus = computed(() => getUserStatusForRoom(props.room));
  const goToRoom = useGoToRoom();
  const routeName = useCurrentRouteName();
  const { t } = useI18n();

  const isRoom = computed(() => props.room.type === RoomType.Room);
  const isFilterArchive = computed(() => props.type === RoomFilterType.Archive);

  const isActive = computed(
    () => routeName.value?.includes('dashboard-rooms-roomId') && Number(route.params.roomId) === props.room.id
  );

  const cardTypeClasses = computed(() => {
    if (isActive.value) {
      return 'border-card-notify-border bg-card-notify-active';
    }
    if (isFilterArchive.value || !isRoom.value) {
      return 'border-card-placeholder bg-card-placeholder';
    }
    return 'border-card-placeholder-border bg-card';
  });

  const labelInfoAttrs = computed(() => {
    if (isFilterArchive.value) {
      return false;
    }
    const attrs = {
      tooltip: '',
      count: 0,
      props: {
        variant: 'primary',
        icon: ''
      }
    };
    if (isRoom.value && !memberUserStatus.value.waiting) {
      if (!props.room.is_visible_history || props.room.unread_messages_count <= 0) {
        return false;
      }
      attrs.count = props.room.unread_messages_count;
      return attrs;
    }

    attrs.props.icon = 'schedule';
    if (memberUserStatus.value.waiting) {
      attrs.tooltip = t('Your room request is pending approval.');
      attrs.props.variant = 'warning-light';
    } else {
      attrs.tooltip = t('Your idea is waiting for approval.');
      attrs.props.variant = 'warning';
    }
    return attrs;
  });
</script>
