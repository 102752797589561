<template>
  <ui-card class="max-h-full overflow-y-auto relative">
    <ui-tabs
      :model-value="myRoomStore.activeType"
      :tabs="tabs"
      header-classes="mx-auto w-full mb-4"
      wrap-content-classes="h-full flex flex-col justify-between flex-1 max-h-[calc(100%-68px)]"
      class="h-full flex flex-col"
      @update:model-value="myRoomStore.setActiveType"
    />
  </ui-card>
</template>

<script setup lang="ts">
  import { CommonRoomsList } from '#components';
  import { RoomFilterType } from '~/globals/RoomFilterType';
  const myRoomStore = useMyRoomStore();
  const tabs = computed(() => {
    return [
      {
        name: 'Rooms',
        key: RoomFilterType.Member,
        component: CommonRoomsList,
        bind: { type: RoomFilterType.Member }
      },
      {
        name: 'My Rooms',
        key: RoomFilterType.Admin,
        component: CommonRoomsList,
        bind: { type: RoomFilterType.Admin }
      },
      {
        name: 'Archive',
        key: RoomFilterType.Archive,
        component: CommonRoomsList,
        bind: { type: RoomFilterType.Archive }
      }
    ];
  });
</script>
